import React, { useState } from "react";
import * as Style from "../css/mapStyle";
import { useStaticQuery, graphql } from "gatsby";
import { useGlobal } from "reactn";
import ReactMapGL, { Marker } from "react-map-gl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import "../../node_modules/mapbox-gl/src/css/mapbox-gl.css";

const ReactMap = props => {
  const [viewport, setViewport] = useState({
    latitude: props.lat,
    longitude: props.lng,
    width: "100%",
    height: "20vh",
    zoom: 13
  });

  return (
    <div>
      <ReactMapGL
        {...viewport}
        // mapboxApiAccessToken= {process.env.MAP_TOKEN}
        mapboxApiAccessToken={
          "pk.eyJ1Ijoic3R1cmVwbGFuc2dydXBwZW4iLCJhIjoiY2p6NDdsZTNuMDB1YzNjdGRqa3pzYnd0MSJ9.ez4z7_BUlCjXJC20YWiyMA"
        }
        onViewportChange={viewport => {
          setViewport(viewport);
        }}
      >
        <Marker latitude={props.lat} longitude={props.lng}>
          <FontAwesomeIcon icon={faMapMarkerAlt} style={{ fontSize: "30px" }} />
        </Marker>
      </ReactMapGL>
    </div>
  );
};

const Map = () => {
  let [english] = useGlobal("English");
  let data = useStaticQuery(
    graphql`
      query {
        contentfulKontakt {
          beskadress {
            childContentfulRichText {
              html
            }
          }
          postadress
          telefonVxel
          email
          longitude
          latitude
        }
      }
    `
  );

  let {
    beskadress,
    postadress,
    telefonVxel,
    email,
    longitude,
    latitude
  } = data.contentfulKontakt;

  let PhoneTo = telefonVxel.replace("-", "").replace(/ /g, "");
  PhoneTo = "tel:" + PhoneTo;

  let MailTo = "mailto:" + email;

  return (
    <Style.ContentContainer>
      <Style.DivLeft>
        <h2>{english ? "HEAD OFFICE" : "HUVUDKONTORET"}</h2>
        <hr />
      </Style.DivLeft>

      <Style.DivRight>
        <Style.VisitingInformation>
          <Style.FlexContactContainer>
            <Style.contactContainer>
              <h4>{english ? "VISITING ADDRESS" : "BESÖKSADRESS"}</h4>
              <a
                href="https://goo.gl/maps/MjBCuW8eckg8KZt46"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: beskadress.childContentfulRichText.html
                  }}
                />
              </a>
            </Style.contactContainer>

            <Style.contactContainer>
              <h4>{english ? "POSTAL" : "POSTADRESS"}</h4>
              <p>{postadress}</p>
            </Style.contactContainer>
          </Style.FlexContactContainer>

          <Style.FlexContactContainer>
        
            <Style.contactContainer>
              <h4>EMAIL</h4>
              <a href={MailTo}>
                <p>{email}</p>
              </a>
            </Style.contactContainer>
          </Style.FlexContactContainer>
        </Style.VisitingInformation>

        <Style.MapContainer>
          <ReactMap lat={latitude} lng={longitude} />
        </Style.MapContainer>
      </Style.DivRight>
    </Style.ContentContainer>
  );
};

export default Map;
