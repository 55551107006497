import React from "react";
import { useGlobal } from "reactn";
import * as Style from "../css/mapStyle";
import * as StyleEmployee from "../css/employeeStyle";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import getLang from "./scripts/getLang";
import PhoneIcon from "../assets/icons/phone-symbol-of-an-auricular-inside-a-circle.svg";
import MailIcon from "../assets/icons/mail.png";

const Employee = props => {
  let [english] = useGlobal("English");
  let data = useStaticQuery(
    graphql`
      query {
        allContentfulAnstallda(sort: { fields: [order], order: ASC }) {
          edges {
            node {
              node_locale
              order
              namn
              title
              email
              telefonnummer
              extraText
              bild {
                fluid(quality: 100) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  );

  data = getLang(english, data.allContentfulAnstallda);

  const renderEmployee = () => {
    return (data
      .filter(i => i.node.namn !== "DO NOT TOUCH")
      .map((item, index) => {
        return (
          <StyleEmployee.EmployeeContainer key={index}>
            {item.node.bild ? (
              <Img fluid={item.node.bild.fluid} />
            ) : (
              <StyleEmployee.EmployeeNoPicture>
                <FontAwesomeIcon icon={faUserCircle} />
              </StyleEmployee.EmployeeNoPicture>
            )}

            <StyleEmployee.EmployeeContent>
              <p>{item.node.namn}</p>
              <p>{item.node.title}</p>
              <hr />

              <StyleEmployee.icons>
                <p>{item.node.extraText ? item.node.extraText : null}</p>
                {item.node.email ? (
                  <a
                    href={"mailto:" + item.node.email}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={MailIcon} alt={"mailicon"} />
                  </a>
                ) : null}

                {item.node.telefonnummer ? (
                  <a
                    href={"tel:" + item.node.telefonnummer}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={PhoneIcon} alt={"phoneicon"} />
                  </a>
                ) : null}
              </StyleEmployee.icons>
            </StyleEmployee.EmployeeContent>
          </StyleEmployee.EmployeeContainer>
        );
      })
    )
  };

  return (
    <Style.ContentContainer>
      <Style.DivLeft>
        <h2>STUREPLANSGRUPPEN</h2>
        <hr />
      </Style.DivLeft>

      <Style.DivRight>
        <StyleEmployee.DivRightContainer>
          {renderEmployee()}
        </StyleEmployee.DivRightContainer>
      </Style.DivRight>
    </Style.ContentContainer>
  );
};

export default Employee;
