import styled from "styled-components";

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: auto;
  max-width: 1400px;
  margin: auto;
  margin-top: 3%;
  margin-bottom: 3%;
  * p {
    font-family: StureplansgruppenUnderRubrik;
  }
  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }
`;

export const DivLeft = styled.div`
  width: 30%;
  h2 {
    margin: 3% 0 0 0;
  }
  hr {
    max-width: 350px;
    margin-left: 0;
  }
  @media only screen and (max-width: 992px) {
    width: 100%;
    margin: auto;
    text-align: center;
    hr {
      max-width: none;
    }
  }
`;
export const DivRight = styled.div`
  width: 70%;
  height: auto;
  @media only screen and (max-width: 992px) {
    width: 100%;
    text-align: center;
  }
`;

export const VisitingInformation = styled.div`
  width: 70%;
  display: flex;
  flex-direction: row;
  float: right;

  @media only screen and (max-width: 992px) {
    width: 100%;
    margin: auto;
    flex-direction: column;
    :first-child div {
      width: 100%;
    }
  }
`;

export const MapContainer = styled.div`
  margin-top: 3%;
  width: 90%;
  float: right;
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`;

export const contactContainer = styled.div `
  width: 100%;
  margin: 10px 0;

  a {
    color: black !important;
  }

  h4 {
    margin-bottom: 0;
  }

  p {
    margin-bottom:0;
  }
`
export const FlexContactContainer = styled.div `
width:100%;
display:flex;
flex-direction:column;
justify-content:space-between;

`
