import styled from "styled-components";

export const DivRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  height: 100%;
  margin-left: 10%;
  justify-content: space-between;
  flex-wrap: wrap;
  @media only screen and (max-width: 992px) {
    margin: auto;
  }
`;

export const EmployeeContainer = styled.div`
  width: 30%;
  height: auto;
  min-width: 195px;
  margin-bottom: 15px;
  * p {
    font-family: StureplansgruppenUnderRubrik;
  }
  div {
    height: 70%;
    object-fit: contain;
  }
  @media only screen and (max-width: 992px) {
    width: 80%;
    margin: auto;
    margin-bottom: 15%;
  }
`;

export const EmployeeNoPicture = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10vw;
  @media only screen and (max-width: 992px) {
    font-size: 25vw;
  }
`;

export const EmployeeContent = styled.div`
  margin: 5% 0 0 0;
  
  hr {
    margin: 3px 0 7px 0;
  }
  p {
    margin: 0;
  }
`;

export const icons = styled.div `
width:100%;
height:auto;
  a {
    color: black;

    img{
      width:30px;
      margin:5px;
    }
  }
  @media only screen and (max-width: 992px) {
        a {
          img{
            width:10vw;
            max-width:48px ;
            
          }
        }
      }

`