import React from "react";
import { useGlobal } from "reactn";
import App from "./app";
import SEO from "../components/seo";
import Map from "../components/map";
import Employee from "../components/employee";
import { useStaticQuery, graphql } from "gatsby";
import getLang from "../components/scripts/getLang";
import Hero from "../components/hero";

const ContactPage = () => {
  let [english] = useGlobal("English");
  let data = useStaticQuery(
    graphql`
      query {
        allContentfulKontakt {
          edges {
            node {
              node_locale
              seoTitel
              seoDescription
              heroBild {
                fluid(quality: 100) {
                  ...GatsbyContentfulFluid
                }
              }
              heroRubrik
              heroUnderRubrik {
                heroUnderRubrik
              }
            }
          }
        }
      }
    `
  );
  let heroData = getLang(english, data.allContentfulKontakt);
  let { seoTitel, seoDescription } = heroData[0].node;
  return (
    <App>
      <SEO title={seoTitel} description={seoDescription} />
      <Hero data={heroData} heroTema={null} img={"img1"} />
      <Map />
      <Employee />
    </App>
  );
};

export default ContactPage;
